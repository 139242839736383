/* App Container Styling */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  padding-top: -20px;
}

/* App Heading Styling */
.app-heading {
  font-family: "Arial", sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.last-modified {
  text-align: center;
  color: #888;
  font-style: italic;
  font-size: 12px;
  margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 500px) {
  .app-heading {
    font-size: 28px;
  }
}
