/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

thead th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tbody tr {
  font-size: 70%; /* Reduce font size by 20% */
}

/* Search Input Styling */
.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.search-input {
  width: 400px;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  outline: none;
}

.search-input:focus {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.no-results {
  text-align: center;
  color: #888;
  font-style: italic;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .search-input {
    width: 100%;
  }
}
