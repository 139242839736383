.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  width: 400px;
}

/* Login Form Styling */
.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.login-form label {
  font-size: 16px;
  margin-bottom: 10px;
}

.login-form input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  outline: none;
  margin-bottom: 20px;
}

.login-form button {
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #555;
}

.login-error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.powered-by {
  text-align: center;
  color: #888;
  font-style: italic;
  font-size: 10px;
  margin-bottom: 20px;
}
